
.course-box {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tab-list-box {
    background: #F3F6FF;
    border-radius: 4px;
    padding: 15px 24px 5px;
    .tab-list {
      color: #414141;
      .tab-item {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 2px;
        transition: all .3s;
        margin: 0 10px 10px 0;
        &.current {
          color: #1F34E4;
          background: #BEC3F2;
        }
      }
    }
  }
  .course-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    width: calc(100% + 16px);
    .course-item {
      width: calc(25% - 16px);
      margin: 20px 0 0 16px;
      .item-img {
        width: 100%;
        height: 0;
        padding-bottom: 58%;
        position: relative;
        .item-img-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../../../assets/image/teacherDataCenter/course-bg.png") no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          padding: 20px;
          box-sizing: border-box;
        }
      }
      .item-content {
        border: 1px solid #ddd;
        border-top: none;
        border-radius: 0 0 4px 4px;
        padding: 10px 16px 16px;
        .item-title {
          font-size: 16px;
          height: 23px;
        }
        .item-bottom {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background: #D9D9D9;
            }
            .name {
              margin-left: 10px;
            }
          }
          .to-detail {
            color: #2A3EE5;
          }
        }
      }
    }
  }
}
.course-list-box {
  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }
}
